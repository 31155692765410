<template>
  <div>
    <Card>
      <template #title>{{ parameter.name }}</template>
      <template #subtitle>{{ parameter.parameterId === null ? 'Создание' : 'Изменение' }}</template>
      <template #content>
        <AbstractForm :loading="loading" v-model="form" :on-commit="createOrUpdate" commit-label="Применить"></AbstractForm>
      </template>
    </Card>
  </div>
</template>

<script>
import FormModel from "@/model/FormModel";
import AbstractForm from "@/components/AbstractForm";
import CRUDService from "@/service/CRUDService";
import ResponseService from "@/service/ResponseService";

export default {
  name: "ParameterEdit",
  components: {AbstractForm},
  props: {
    parameter: {
      type: Object,
      required: true
    },
    after: {
      type: Function
    }
  },
  data() {
    return {
      loading: false,
      form: {
        value: new FormModel('Значение параметра', {
          value: this.parameter.value
        }),
      }
    }
  },
  methods: {
    createOrUpdate() {
      this.loading = true
      let data = {
        value: this.form.value.value,
        type: this.parameter.id
      }
      if (this.parameter.parameterId !== null) {
        CRUDService.update('parameter', data, this.parameter.parameterId).then((res) => {
          this.$toast.add({
            severity: 'success',
            summary: 'Значение обновлено',
            detail: 'Значение параметра "' + res.type.name + '" обновлено',
            life: 2000
          })
          if (this.after) {
            this.after()
          }
          this.loading = false
        }).catch((err) => {
          ResponseService.handleErrorResponseWithForm(err, this.form, this.$toast)
          this.loading = false
        })
      } else {
        CRUDService.create('parameter', data).then((res) => {
          this.$toast.add({
            severity: 'success',
            summary: 'Значение создано',
            detail: 'Значение параметра "' + res.type.name + '" создано',
            life: 2000
          })
          if (this.after) {
            this.after()
          }
          this.loading = false
        }).catch((err) => {
          ResponseService.handleErrorResponseWithForm(err, this.form, this.$toast)
          this.loading = false
        })
      }
    }
  }
}
</script>

<style scoped>

</style>