<template>
  <div>
    <Card>
      <template #title>Параметры</template>
      <template #content>
        <AbstractTable ref="table" code="parameters" display-filter="menu" expander>
          <template #code="row">
            {{ row.data.name }}
          </template>
          <template #expand="{row}">
            <ParameterEdit :parameter="row.data" :after="afterUpdate"></ParameterEdit>
          </template>
        </AbstractTable>
      </template>
    </Card>
  </div>
</template>

<script>
import AbstractTable from "@/components/AbstractTable";
import ParameterEdit from "@/components/ParameterEdit";
export default {
  name: "ParametersTable",
  components: {ParameterEdit, AbstractTable},
  methods: {
    afterUpdate() {
      this.$refs.table.getData()
    }
  }
}
</script>

<style scoped>

</style>