<template>
  <div class="grid">
    <div class="col-12">
      <ParametersTable></ParametersTable>
    </div>
  </div>
</template>

<script>
import ParametersTable from "@/components/tables/ParametersTable";
export default {
  name: "Parameters",
  components: {ParametersTable}
}
</script>

<style scoped>

</style>